import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import rutas from '@/router/_config'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

function configRoutes() {
  return rutas.listado
}

router.beforeEach((to, from, next) => {
  // Check login
  if (to.path === '/login' && store.getters.authStatus) {
    next({ name: 'Inicio' })
    return
  }
  // Check Auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.authStatus) {
      next({ name: 'login' })
      return
    }
  }
  // Check tipos
  if (to.matched.some((record) => record.meta.tipo_admin)) {
    if (store.getters.getUserTipo !== 'ADMINISTRADOR') {
      next({ name: 'login' })
      return
    }
  }
  if (to.matched.some((record) => record.meta.tipo_capacitador)) {
    if (store.getters.getUserTipo !== 'CAPACITADOR') {
      next({ name: 'login' })
      return
    }
  }
  // Check acceso exclusivo para ADMINISTRADORES Superadmin (rol_id 3)
  if (to.matched.some((record) => record.meta.rol_super)) {
    if (store.getters.getUserTipo !== 'ADMINISTRADOR') {
      next({ name: 'login' })
      return
    }
    if (store.getters.getUserModelo.rol_id !== 3) {
      next({ name: 'login' })
      return
    }
  }

  // Check acceso para ADMINISTRADORES Superadmin (rol_id 3) y Visor (rol_id 1)
  if (to.matched.some((record) => record.meta.rol_super_visor)) {
    if (
      store.getters.getUserTipo !== 'ADMINISTRADOR' ||
      ![1, 3].includes(store.getters.getUserModelo.rol_id)
    ) {
      next({ name: 'login' })
      return
    }
  }

  next()
})
export default router
