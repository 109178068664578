import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Crypto from 'crypto-js'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { API } from '@/plugins/api'

Vue.use(Vuex)
const storageKey = 'token'
const cookieName = 'cae18158bda2c54186f025c90a818082'

// Recibe el token encriptado o genera uno
const encryptionToken = Cookies.get(cookieName) || uuidv4()
// Guarda la cookie encriptada en una cookie segura
Cookies.set(cookieName, encryptionToken, { expires: 180 })
const vuexPersistence = new VuexPersistence({
  // Edito le funcionamiento del storage, reescribiendo funciones
  storage: {
    getItem: () => {
      const store = window.sessionStorage.getItem(storageKey)

      if (store) {
        try {
          // Desencripta el store recibido desde el storage, usando el token de la cookie cifrada
          const bytes = Crypto.AES.decrypt(store, encryptionToken)

          return JSON.parse(bytes.toString(Crypto.enc.Utf8))
        } catch (e) {
          // error si todo falla
          window.sessionStorage.removeItem(storageKey)
        }
      }

      return null
    },
    setItem: (key, value) => {
      // Encripta el store
      const store = Crypto.AES.encrypt(value, encryptionToken).toString()

      // Guarda el store encriptado
      return window.sessionStorage.setItem(storageKey, store)
    },
    removeItem: () => window.sessionStorage.removeItem(storageKey),
  },
})

export default new Vuex.Store({
  state: {
    user: {},
    token: null,
    status: false,
    shouldClearFields: false, // Nuevo estado para limpiar campos
    totalRegistros: 0, // Valor inicial
    filtersTablaCapacitaciones: {
      filterText: '',
      filterAnio: new Date().getFullYear(),
      filterDireccion: null,
      filterModalidad: null,
    },
    filtersTablaInscripciones: {
      filterAnio: new Date().getFullYear(),
      // filterText: '',
    },
  },
  mutations: {
    auth_success(state, { user, token }) {
      state.user = user
      state.token = token
      state.status = true
    },
    logout(state) {
      state.user = {}
      state.token = null
      state.status = false
    },
    triggerClearFields(state) {
      // Nueva mutación
      state.shouldClearFields = true
    },
    clearFieldsHandled(state) {
      // Nueva mutación para resetear el estado
      state.shouldClearFields = false
    },
    SET_TOTAL_REGISTROS(state, total) {
      state.totalRegistros = total
    },
    SET_FILTERS_CAPACITACIONES(state, filters) {
      state.filtersTablaCapacitaciones = filters
    },
    // RESET_FILTERS_CAPACITACIONES(state) {
    //   state.filtersTablaCapacitaciones = {
    //     filterText: '',
    //     // filterAnio: new Date().getFullYear(),
    //     filterDireccion: null,
    //     filterModalidad: null,
    //   }
    // },
    SET_FILTERS_INSCRIPCIONES(state, filters) {
      state.filtersTablaInscripciones = filters
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        API.post('/api/auth/login', user)
          .then((response) => {
            const user = response.data.data.usuario
            const token = response.data.data.token
            commit('auth_success', { user, token })
            resolve(response)
          })
          .catch((error) => {
            sessionStorage.removeItem(storageKey)
            reject(error)
          })
      })
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        API.post('/api/auth/logout')
          .then((response) => {
            commit('logout')
            sessionStorage.removeItem(storageKey)
            Cookies.remove(cookieName)
            resolve()
          })
          .catch(() => {
            commit('logout')
            sessionStorage.removeItem(storageKey)
            Cookies.remove(cookieName)
            resolve()
          })
      })
    },

    cleanLog({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        sessionStorage.removeItem(storageKey)
        Cookies.remove(cookieName)
        resolve()
      })
    },

    triggerClearFields({ commit }) {
      // Nueva acción
      commit('triggerClearFields')
    },

    clearFieldsHandled({ commit }) {
      // Nueva acción
      commit('clearFieldsHandled')
    },

    actualizarTotalRegistros({ commit }, total) {
      commit('SET_TOTAL_REGISTROS', total)
    },
    updateFiltersCapacitaciones({ commit }, filters) {
      commit('SET_FILTERS_CAPACITACIONES', filters)
    },
    // resetFiltersCapacitaciones({ commit }) { //no me sirve para la logica que uso
    //   commit('RESET_FILTERS_CAPACITACIONES')
    // },
    updateFiltersInscripciones({ commit }, filters) {
      commit('SET_FILTERS_INSCRIPCIONES', filters)
    },
  },
  getters: {
    authStatus: (state) => state.status,
    getUserName: (state) => {
      if (Object.keys(state.user).length === 0) return ''
      else
        return (
          state.user.usuarioable.apellido + ', ' + state.user.usuarioable.nombre
        )
    },
    getToken: (state) => state.token,
    getUserTipo: (state) => state.user.tipo,
    getUserModelo: (state) => state.user.usuarioable,
    shouldClearFields: (state) => state.shouldClearFields, // Nuevo getter
    getTotalRegistros: (state) => state.totalRegistros,
    getFiltersCapacitaciones: (state) => state.filtersTablaCapacitaciones,
    getFiltersInscripciones: (state) => state.filtersTablaInscripciones,
  },
  plugins: [vuexPersistence.plugin],
})
