import Toast from 'vue-toastification'
import Vue from 'vue'
import 'vue-toastification/dist/index.css'

const options = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
}

Vue.use(Toast, options)
