import Vue from 'vue'
// import XLSX from 'xlsx'
import * as XLSX from 'xlsx'
import { API } from '@/plugins/api'

Vue.prototype.$makeToast = function (
  mensajeColor,
  mensajeCabecera,
  mensajeCuerpo
) {
  this.$bvToast.toast(mensajeCuerpo, {
    title: mensajeCabecera,
    variant: mensajeColor,
    solid: true,
    toaster: 'b-toaster-top-right',
    appendToast: true,
  })
}

Vue.prototype.$validateState = function (ref) {
  if (
    this.veeFields[ref] &&
    (this.veeFields[ref].dirty || this.veeFields[ref].validated)
  ) {
    return !this.errors.has(ref)
  }
  return null
}

Vue.prototype.$fetchVuetable = async function (apiUrl, httpOptions) {
  const datos = await API.get(apiUrl, httpOptions)
  // Guarda el total en el estado de Vuex
  this.$store.dispatch('actualizarTotalRegistros', datos.data.data.total)
  return datos.data
  // return datos.data si en el controlador de vue uso sendResponse o api() sino solo datos
}

Vue.prototype.$makeExcel = function (endpoint, filename, form) {
  API.post(endpoint, { form })
    .then((response) => {
      this.dataToExport = response.data.data
      // console.log('endpoint', endpoint)
      console.log('this.dataToExport', response.data.data)
      const data = XLSX.utils.json_to_sheet(this.dataToExport)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    })
    .catch(() => {
      this.$swal({
        position: 'center',
        type: 'error',
        title: 'Error al generar informe',
        text: 'Intente nuevamente',
        showConfirmButton: true,
      })
    })
}

// this.$makeToast(
//   'danger',
//   'Error de Conexion',
//   'Revise su conexion y presione F5 para reintentar'
// )
